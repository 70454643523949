/* Color */
$white: #ffffff; //color to background
$cream: #eae8e4; //color to background
$light-cream: #f2f0ec; //color to background
$kale: #383a33;
$light-kale: #9c9d99;
$mint: #d4dac3; //color to background
$mint-nutri: #C9D0B3; //color to nutri background
$grana: #ff4d4d;
$light-grana: #ffb8b8;
$corn: #ffc800; //color to background
$light-corn: #fff7d9; //color to background
$olive: #718238;
$light-olive: #D7EBB9;
$salmon: #ff6375;
$orange: #ff8224;
$beetroot: #b1005a;
$tuna: #4a66f6;
$eggplant: #8445ff;
$blueberry: #464196;

/* Radio */
$radius-xs: 0.8rem;
$radius-s: 1.2rem;
$radius-m: 2rem;
$radius-l: 3rem;
$radius-xl: 6rem;
$radius-xxl: 9rem;
$radius-xxxl: 18rem;

/* Unidades */
$unid-4: 0.4rem;
$unid-8: 0.8rem;
$unid-12: 1.2rem;
$unid-16: 1.6rem;
$unid-24: 2.4rem;
$unid-32: 3.2rem;
$unid-40: 4rem;
$unid-56: 5.6rem;
$unid-64: 6.4rem;
$unid-80: 8rem;
$unid-120: 12rem;
$unid-160: 16rem;
$unid-240: 24rem;
$unid-320: 32rem;
$unid-480: 48rem;
$unid-560: 56rem;
$unid-640: 64rem;
$unid-720: 72rem;

/* Unidades mov */
$unid-mov-7: 0.75rem;
$unid-mov-15: 1.5rem;

/* Máximos */
$max-desktop-content-width: 134.4rem;
$max-desktop-private-area-content-width: 112.4rem;

/* Media breakpoints */
$media-mobile-xs: 375px;
$media-desktop: 1020px;
$media-tablet: 760px;
